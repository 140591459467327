import { lazy } from 'react';

import LeadRouter from 'modules/lead/LeadRouter';
import MessageRouter from 'modules/messages/SecureMessages/MessageRouter';
import { TextSnippetsWrapper } from 'modules/settings/TextSnippets/TextSnippets';
import QuickbooksAuth from 'modules/settings/quickbooks/QuickbooksAuth';
import NpsSettingsWrapper from 'modules/settings/NpsSettings/NpsSettings';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behavior as already tried refresh
      });
  });
};

const LoginPage = lazy(() => lazyRetry(() => import('modules/authentication/login')));
const SignUpPage = lazy(() => lazyRetry(() => import('modules/authentication/signup')));
const DashboardPage = lazy(() => lazyRetry(() => import('modules/dashboard')));
const ActivateAccountPage = lazy(() => lazyRetry(() => import('modules/authentication/activateAccount')));
const EmailSentMessage = lazy(() => lazyRetry(() => import('modules/authentication/activateAccount/emailSentMessage')));
const ProfilePage = lazy(() => lazyRetry(() => import('modules/profile')));
const ForgotPasswordPage = lazy(() => lazyRetry(() => import('modules/authentication/forgotPassword')));
const UploadPicturePage = lazy(() => lazyRetry(() => import('modules/authentication/activateAccount/UploadPicture')));

const WorkflowAutomation = lazy(() => lazyRetry(() => import('modules/settings/workflow_automation/WorkflowAutomation')));

const ContactRoute = lazy(() => lazyRetry(() => import('modules/contact/ContactRouter')));

const CasesManagementPage = lazy(() => lazyRetry(() => import('modules/cases/CaseRouter')));
const Note = lazy(() => lazyRetry(() => import('modules/cases/Note/Notes.js')));
const FileManagementPage = lazy(() => lazyRetry(() => import('modules/file_manager/GlobalFileManager')));
const FileTemplatePage = lazy(() => lazyRetry(() => import('modules/templates')));
const BuilderPage = lazy(() => lazyRetry(() => import('modules/templates/templateBuilder2')));
const ZohoSignatureRequest = lazy(() => lazyRetry(() => import('modules/file_manager/ZohoEsignRequest')));

const ReportGeneratePage = lazy(() => lazyRetry(() => import('modules/templates/reportGenerator')));

const TenantSelection = lazy(() => lazyRetry(() => import('modules/authentication/TenantSelection.js')));
const ClientDashboard = lazy(() => lazyRetry(() => import('modules/client/Dashboard/ClientDashboard')));
const ClientProfile = lazy(() => lazyRetry(() => import('modules/client/Profile.js')));
const TasksMainScreen = lazy(() => lazyRetry(() => import('modules/Tasks/Tasks.jsx')));
const BillingDashboard = lazy(() => lazyRetry(() => import('modules/billing/Billing')));
const SignDocumentPage = lazy(() => lazyRetry(() => import('modules/client/signDocumentPage.js')));
const ClientFileDetails = lazy(() => lazyRetry(() => import('modules/client/fileDetails')));
const GlobalCalendar = lazy(() => lazyRetry(() => import('modules/calendar/GlobalCalendar')));

// Settings related route
const TeamManagement = lazy(() => lazyRetry(() => import('modules/settings/TeamManagement/TeamManagement')));
const userDetailsPage = lazy(() => lazyRetry(() => import('modules/settings/TeamManagement/UserDetails/UserDetailsPage')));
const Groups = lazy(() => lazyRetry(() => import('modules/settings/GroupsWrapper.jsx')));
const CustomFieldsPage = lazy(() => lazyRetry(() => import('modules/settings/CustomField/CustomFields')));
const OfficeLocation = lazy(() => lazyRetry(() => import('modules/settings/OfficeLocations/OfficeLocations')));
const Accounts = lazy(() => lazyRetry(() => import('modules/settings/Accounts/Accounts')));
const ExpenseCodes = lazy(() => lazyRetry(() => import('modules/settings/ExpenseCodes/ExpenseCodes')));
const PaymentStructure = lazy(() => lazyRetry(() => import('modules/settings/PaymentStructure/PaymentStructure')));
const GeneralSettings = lazy(() => lazyRetry(() => import('modules/settings/GeneralSettings/GeneralSettings')));
const ReviewRequestUrls = lazy(() => lazyRetry(() => import('modules/settings/ReviewRequestUrls/ReviewRequestUrls')));
const LeadSourcePage = lazy(() => lazyRetry(() => import('modules/settings/LeadSource/LeadSources')));
const CustomDescriptors = lazy(() => lazyRetry(() => import('modules/settings/CustomDescriptors/CustomDescriptors.jsx')));
const Subscription = lazy(() => lazyRetry(() => import('modules/settings/subscription/index')));
const ManageCards = lazy(() => lazyRetry(() => import('modules/settings/ManageCards/ManageCardsWrapped')));
const BillingCodes = lazy(() => lazyRetry(() => import('modules/settings/BillingCodes/BillingCodes.jsx')));
const NpsForm = lazy(() => lazyRetry(() => import('modules/settings/NpsSettings/NpsForm.jsx')));

const PaymentRequestDetails = lazy(() => lazyRetry(() => import('modules/client/PaymentRequestDetails')));
const ClientBilling = lazy(() => lazyRetry(() => import('modules/client/ClientBilling')));
const ClientMessage = lazy(() => lazyRetry(() => import('modules/client/messages/MessageClientRouter')));
const ClientCalendar = lazy(() => lazyRetry(() => import('modules/client/calendar/ClientCalendar')));
const ClientTasks = lazy(() => lazyRetry(() => import('modules/client/task')));
const ClientFileManager = lazy(() => lazyRetry(() => import('modules/client/Files/ClientFileManager')));

const InvoiceDetails = lazy(() => lazyRetry(() => import('modules/client/InvoiceDetails')));
const TextMessages = lazy(() => lazyRetry(() => import('modules/messages/TextMessages/TextMessages.jsx')));
const ReportsPage = lazy(() => lazyRetry(() => import('modules/reports/Reports.jsx')));
const AdvancedSearchPage = lazy(() => lazyRetry(() => import('modules/advancedSearch/AdvancedSearch.jsx')));

const IntakeRouter = lazy(() => lazyRetry(() => import('modules/intakeforms/IntakeRouter')));

const ClientInTakePreview = lazy(() => lazyRetry(() => import('modules/intakeforms/PreviewForm/ClientInTakePreview')));
const LeadIntakePreview = lazy(() => lazyRetry(() => import('modules/intakeforms/PreviewForm/LeadIntakePreview')));
const SharedFilesList = lazy(() => lazyRetry(() => import('components/FileSharing/SharedFilesList')));

export const mainRoutes = [
  {
    path: '/signup',
    component: SignUpPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/account-activate',
    component: ActivateAccountPage,
  },
  {
    path: '/email-sent',
    component: EmailSentMessage,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
  },
  {
    path: '/tenant-selection',
    component: TenantSelection,
    permission: 'tenent',
    showSideBar: false,
    module: 'common',
  },
  {
    path: '/sign',
    component: SignDocumentPage,
    // permission: 'tenent',
    showSideBar: false,
    // module: 'common',
  },
  {
    path: '/preview-form',
    component: ClientInTakePreview,
    showSideBar: false,
  },
  {
    path: '/preview-lead-form',
    component: LeadIntakePreview,
    showSideBar: false,
  },
  {
    path: '/lawft-link',
    component: SharedFilesList,
  },
  {
    path: '/nps',
    component: NpsForm,
  },
];

export const subRoutes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    permission: 'dashboard',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    permission: 'profile',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/notes',
    component: Note,
    permission: 'case',
    showSideBar: true,
    module: 'notes',
    exact: true,
  },
  {
    path: '/file-manager',
    component: FileManagementPage,
    permission: 'files',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/signature-request',
    component: ZohoSignatureRequest,
    permission: 'files',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/templates',
    component: FileTemplatePage,
    permission: 'files',
    showSideBar: true,
    module: 'document_assembly',
    exact: true,
  },
  {
    path: '/templates/builder',
    component: BuilderPage,
    permission: 'files',
    showSideBar: true,
    module: 'document_assembly',
    exact: true,
  },
  {
    path: '/templates/generate',
    component: ReportGeneratePage,
    permission: 'files',
    showSideBar: true,
    module: 'document_assembly',
    exact: true,
  },
  {
    path: '/cases',
    component: CasesManagementPage,
    permission: 'cases',
    showSideBar: true,
    module: 'case_management',
    exact: false,
  },
  {
    path: '/leads',
    component: LeadRouter,
    permission: 'leads',
    showSideBar: true,
    module: 'lead_management',
    exact: false,
  },
  {
    path: '/tasks',
    component: TasksMainScreen,
    permission: 'tasks',
    showSideBar: true,
    module: 'task_management',
    exact: true,
  },
  {
    path: '/settings',
    component: TeamManagement,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/Groups',
    component: Groups,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/team-management',
    component: TeamManagement,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/team-management/:id',
    component: userDetailsPage,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/workflow-automation',
    component: WorkflowAutomation,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'automated_repeatable_workflows',
  },
  {
    path: '/settings/custom-fields',
    component: CustomFieldsPage,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'custom_fields',
    exact: true,
  },
  {
    path: '/settings/office-locations',
    component: OfficeLocation,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/billing-codes',
    component: BillingCodes,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/lead-sources',
    component: LeadSourcePage,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/custom-descriptors',
    component: CustomDescriptors,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/custom-descriptors',
    component: CustomDescriptors,
    permission: 'firm-settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/expense-codes',
    component: ExpenseCodes,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/payment-structure',
    component: PaymentStructure,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/general-settings',
    component: GeneralSettings,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/request-review-urls',
    component: ReviewRequestUrls,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/accounts',
    component: Accounts,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/contacts',
    component: ContactRoute,
    permission: 'contacts',
    showSideBar: true,
    module: 'contact_management',
    exact: false,
  },
  {
    path: '/client/dashboard',
    component: ClientDashboard,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/client/profile',
    component: ClientProfile,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/billing',
    component: BillingDashboard,
    permission: 'billing',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/messages',
    component: MessageRouter,
    permission: 'messages',
    showSideBar: true,
    module: 'secure_messages',
    // exact: true,
  },
  {
    path: '/calendar',
    component: GlobalCalendar,
    permission: 'calendar',
    showSideBar: true,
    module: 'calendaring',
    exact: false,
  },
  {
    path: '/sign-document',
    component: SignDocumentPage,
    permission: 'client',
    showSideBar: true,
    module: 'e_signature',
    exact: true,
  },
  {
    path: '/client/files',
    component: ClientFileManager,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/client/calendar',
    component: ClientCalendar,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/client/tasks',
    component: ClientTasks,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/client/messages',
    component: ClientMessage,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: false,
  },
  {
    path: '/client/filedetails/:filePk/:fileSk',
    component: ClientFileDetails,
    permission: 'client',
    showSideBar: true,
    module: 'client_portal',
    exact: true,
  },
  {
    path: '/payment-details',
    component: PaymentRequestDetails,
    permission: 'client',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/client-billing',
    component: ClientBilling,
    permission: 'client',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/invoice-details',
    component: InvoiceDetails,
    permission: 'client',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/text-messages',
    component: TextMessages,
    permission: 'client',
    showSideBar: true,
    module: 'phone_messages',
    exact: true,
  },
  {
    path: '/reports',
    component: ReportsPage,
    permission: 'reports',
    showSideBar: true,
    module: 'reports',
    exact: false,
  },
  {
    path: '/search',
    component: AdvancedSearchPage,
    permission: 'case',
    showSideBar: true,
    module: 'common',
    exact: false,
  },
  {
    path: '/settings/subscription',
    component: Subscription,
    permission: 'subscription',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/saved-cards',
    component: ManageCards,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/snippets',
    component: TextSnippetsWrapper,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/intake-forms',
    component: IntakeRouter,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: false,
  },
  {
    path: '/client/upload-picture',
    component: UploadPicturePage,
    permission: 'client',
    showSideBar: false,
    module: 'common',
    exact: true,
  },
  {
    path: '/quickbooks',
    component: QuickbooksAuth,
    permission: 'client',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
  {
    path: '/settings/nps',
    component: NpsSettingsWrapper,
    permission: 'settings',
    showSideBar: true,
    module: 'common',
    exact: true,
  },
];
