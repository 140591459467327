import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import { convertSecondsToTime, dollarFormat, getPropByString } from 'utils/utils';
import { classNames } from 'primereact/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import moment from 'moment';

const defaultClassName = 'me-2 text-capitalize text-decoration-none';
const emptyDataValue = '-';

export const tableCell = (row, ...rest) => {
  const { header, field } = rest?.[0];
  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{getPropByString(row, field) || '-'}</span>
    </React.Fragment>
  );
};

export const currencyCell = (row, ...rest) => {
  const { header, field } = rest?.[0];
  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{dollarFormat(getPropByString(row, field))}</span>
    </React.Fragment>
  );
};

export const numberCell = (row, ...rest) => {
  const { header, field } = rest?.[0];
  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{getPropByString(row, field) ? Number(getPropByString(row, field)) : '-'}</span>
    </React.Fragment>
  );
};

export const nameTemplate = (rowData, ...rest) => {
  const getLowerCasedCaseNamesArray = (arr = []) => {
    return arr?.map((caseName, idx) => caseName?.toLowerCase());
  };

  const { header, field } = rest?.[0];

  const { is_lead, case_id, case_description, invoice } = rowData || {};
  const caseNamesArr = [case_description?.case_name, rowData?.case_name];
  const isLead = is_lead || getLowerCasedCaseNamesArray(caseNamesArr)?.includes('tbd'); //TBD

  const mainModule = isLead ? '/leads' : '/cases';
  const caseId = case_id || invoice?.case_id;
  const route = caseId ? `${mainModule}/${caseId}` : mainModule;

  const isRedirectionLink = Boolean(case_description?.case_name || rowData?.case_name);

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">
        {isRedirectionLink ? (
          <Link
            // className="cursor-pointer text-decoration-none F-size14 text-bold"
            // to={(rowData?.is_lead ? '/leads/' : '/cases/') + (rowData?.case_id || rowData?.invoice?.case_id)}
            to={route}
            className={`me-2 ${classNames({ 'redirection-link-no-text-transform': isRedirectionLink })}`}
          >
            {getPropByString(rowData, field)}
          </Link>
        ) : (
          emptyDataValue
        )}
      </span>
    </React.Fragment>
  );
};

export const ContactNameTemplate = (rowData, ...rest) => {
  const { header } = rest?.[0];
  const { client_details } = rowData || {};
  let contactName;
  if (client_details) {
    contactName = `${client_details?.first_name} ${client_details?.last_name}`;
  }
  const isRedirectionLink = Boolean(client_details?.first_name);
  const route = client_details?.contact_id ? '/contacts/' + client_details?.contact_id : '/contacts';

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">
        {isRedirectionLink ? (
          <Link to={route} className={`${defaultClassName} ${classNames({ 'redirection-link': isRedirectionLink })}`}>
            {contactName || '-'}
          </Link>
        ) : (
          emptyDataValue
        )}
      </span>
    </React.Fragment>
  );
};

export const leadAttorneyTemplate = (rowData, ...rest) => {
  const { header } = rest?.[0];
  const { leadAttorney } = rowData || {};

  const isRedirectionLink = Boolean(leadAttorney?.attorney_name);
  const route = leadAttorney?.firm_user_id ? '/settings/team-management/' + leadAttorney?.firm_user_id : '/settings/team-management';
  if (isRedirectionLink && leadAttorney?.sk && leadAttorney?.pk && leadAttorney?.firm_user_id) {
    handleSettingSkAndPkToLocalStorage(leadAttorney?.sk, leadAttorney?.pk);
  }

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">
        {isRedirectionLink ? (
          <Link
            // className="cursor-pointer text-capitalize text-decoration-none text-bold"
            // to={'/settings/team-management/' + rowData?.leadAttorney?.client_cognito_username}
            to={route}
            className={`${defaultClassName} ${classNames({ 'redirection-link': isRedirectionLink })}`}
          >
            {leadAttorney?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
            {leadAttorney?.attorney_name || '-'}
          </Link>
        ) : (
          emptyDataValue
        )}
      </span>
    </React.Fragment>
  );
};

export const firmUserCell = (rowData, ...rest) => {
  const { header, field } = rest?.[0];
  const route = rowData?.firm_user_id ? '/settings/team-management/' + rowData?.firm_user_id : '/settings/team-management';
  const isRedirectionLink = Boolean(rowData?.[field]);

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">
        {rowData?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {isRedirectionLink ? (
          <Link to={route} className={`${defaultClassName} ${classNames({ 'redirection-link': isRedirectionLink })}`}>
            {rowData?.[field] || '-'}
          </Link>
        ) : (
          emptyDataValue
        )}
      </span>
    </React.Fragment>
  );
};

export const tagTemplate = (rowData) => {
  return (
    <React.Fragment>
      <span className="p-column-title text-break">Tags</span>
      <span className="text-break">
        {rowData?.case_details?.tags?.map((v, i) => <Tag key={i} value={v} className="d-tag me-1 mb-1" />) || '-'}
      </span>
    </React.Fragment>
  );
};

export const timeTemplate = (row, ...rest) => {
  const { header, field } = rest?.[0];
  return (
    <React.Fragment>
      <span className="p-column-title text-break">Total Time Open</span>
      <span className="text-break">{convertSecondsToTime(getPropByString(row, field)) || '-'}</span>
    </React.Fragment>
  );
};

export const caseStageTemplate = (rowData, ...rest) => {
  const { header, field } = rest?.[0];

  const caseStage = rowData?.case_description?.case_stage?.stage_name;

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{caseStage ? caseStage : '-'}</span>
    </React.Fragment>
  );
};
export const caseMatterTemplate = (rowData, ...rest) => {
  const { header, field } = rest?.[0];

  const caseMatter = rowData?.case_description.case_matter;

  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{caseMatter ? caseMatter : '-'}</span>
    </React.Fragment>
  );
};

export const timePastDueTemplate = (rowData, ...rest) => {
  const { header } = rest?.[0];
  const seconds = moment().diff(moment(rowData?.due_date), 'seconds');
  return (
    <React.Fragment>
      <span className="p-column-title text-break">{header}</span>
      <span className="text-break">{convertSecondsToTime(seconds)}</span>
    </React.Fragment>
  );
};

export const attorneyTemplate = (rowData) => {
  const attorneys = rowData?.attorney_details?.filter((v) => !v?.is_lead_attorney);
  return (
    <React.Fragment>
      <span className="p-column-title text-break">Attorney</span>
      <span className="text-break">
        {attorneys?.map((attorney, i) => {
          const isRedirectionLink = Boolean(attorney?.attorney_name);
          const route = attorney?.firm_user_id ? '/settings/team-management/' + attorney?.firm_user_id : '/settings/team-management';
          if (attorney?.firm_user_id && isRedirectionLink && attorney?.sk && attorney?.pk) {
            handleSettingSkAndPkToLocalStorage(attorney?.sk, attorney?.pk);
          }

          return isRedirectionLink ? (
            <Link
              key={i}
              // className="cursor-pointer me-2 text-capitalize text-decoration-none text-bold"
              // to={'/settings/team-management/' + attorney?.client_cognito_username}
              to={route}
              className={`me-2 text-capitalize text-decoration-none ${classNames({ 'redirection-link': isRedirectionLink })}`}
            >
              {attorney?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
              {attorney?.attorney_name}
              <br />
            </Link>
          ) : (
            emptyDataValue
          );
        }) || emptyDataValue}
      </span>
    </React.Fragment>
  );
};

export const leadCaseManagerTemplate = (rowData) => {
  const leadCaseMangerList = rowData?.attorney_details?.filter((v) => v?.is_lead_case_manager);
  return (
    <React.Fragment>
      <span className="p-column-title text-break">Lead Case Manager</span>
      <span className="text-break">
        {leadCaseMangerList?.map((attorney, i) => {
          const isRedirectionLink = Boolean(attorney?.attorney_name);
          const route = attorney?.firm_user_id ? '/settings/team-management/' + attorney?.firm_user_id : '/settings/team-management';
          if (attorney?.firm_user_id && isRedirectionLink && attorney?.sk && attorney?.pk) {
            handleSettingSkAndPkToLocalStorage(attorney?.sk, attorney?.pk);
          }

          return isRedirectionLink ? (
            <Link
              key={i}
              to={route}
              className={`me-2 text-capitalize text-decoration-none ${classNames({ 'redirection-link': isRedirectionLink })}`}
            >
              {attorney?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
              {attorney?.attorney_name}
              <br />
            </Link>
          ) : (
            emptyDataValue
          );
        }) || emptyDataValue}
      </span>
    </React.Fragment>
  );
};
