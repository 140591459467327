import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { HashRouter as Router, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';

import Layout from 'modules/layout';
import constants from 'constants/index';
import ClientLayout from 'modules/client/Layout';
import SubscriptionNoPermission from 'shared/SubscriptionNoPermission';
import { UserDetailsContext } from 'context/userDetailsContext';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { mainRoutes, subRoutes } from './routes';
import { checkSubdomain, logout } from 'utils/utils';
import { SpinnerComponent } from 'shared/SpinnerComponent';

let cookie = new Cookies();

// Public routes that don't require redirection, even if the user is logged in, go in this array.
const publicRoutesNoRedirect = ['/sign', '/preview-form', '/preview-lead-form', '/lawft-link', '/nps'];

const publicRoutes = ['/', '/login', '/signup', '/account-activate', '/forgot-password', '/email-sent', ...publicRoutesNoRedirect];

const handleLogout = (e) => {
  try {
    Auth.signOut()
      .then((data) => {
        logout(null);
      })
      .catch((error) => {});
  } catch (e) {}
};

const AppRoute = ({ component: Component, permission, path, showSideBar, module, ...rest }) => {
  const location = useLocation();
  const userContext = useContext(UserDetailsContext);

  const token = useMemo(() => cookie.get('token'), []);
  const isSignUp = useMemo(() => cookie.get('isSignUp'), []);
  const access_level = useMemo(() => cookie.get('access_level'), []);

  const checkPermission = (access_level) => {
    if (access_level === 'client' && (permission === 'client' || permission === 'tenent')) {
      return true;
    } else if (access_level !== 'client') {
      if (['profile', 'settings', 'tenent'].includes(permission)) {
        return true;
      } else if (permission === 'firm-settings') {
        return userContext.getFirmPermission(constants?.permissions?.MANAGE_FIRM_SETTINGS);
      } else {
        return !(userContext.getPermission(permission) === constants?.permissions?.HIDDEN);
      }
    }
  };

  const renderComponent = (props) => (
    <Suspense fallback={<SpinnerComponent />}>
      <Component {...props} />
    </Suspense>
  );

  const redirectUser = () => (access_level === 'client' ? <Redirect to="/client/dashboard" /> : <Redirect to="/dashboard" />);

  const handlePublicRoute = (props) => {
    if ((token || isSignUp) && !publicRoutesNoRedirect.includes(props.location.pathname)) return redirectUser();
    return renderComponent(props);
  };

  const handlePrivateRoute = (props) => {
    if (!token) {
      handleSpecialRedirects();
      return <Redirect to="/" />;
    }

    if (checkPermission(access_level)) {
      const LayoutComponent = access_level === 'client' ? ClientLayout : Layout;
      return <LayoutComponent showSideBar={showSideBar}>{renderComponent(props)}</LayoutComponent>;
    }

    return redirectUser();
  };

  const handleSpecialRedirects = () => {
    if (path && (path === '/sign-document' || path === '/client-billing')) {
      const remain = location?.search;
      sessionStorage.setItem('signature_path', JSON.stringify(`${path}${remain}`));
    }
    cookie.set('redirectPath', `${rest?.location?.pathname}${rest?.location?.search}`, {
      path: '/',
      domain: process.env.REACT_APP_DOMAIN,
    });
  };

  const isPublicRoute = publicRoutes.includes(path);

  if (isPublicRoute) {
    return <Route {...rest} render={handlePublicRoute} />;
  }

  if (!(userContext?.subscriptionAccess?.[module] || module === 'common') && token) {
    const LayoutComponent = access_level === 'client' ? ClientLayout : Layout;
    return (
      <LayoutComponent showSideBar={showSideBar}>
        <SubscriptionNoPermission />
      </LayoutComponent>
    );
  }

  return <Route {...rest} render={handlePrivateRoute} />;
};

function AppRouter() {
  const history = useHistory();
  const websocketContext = useContext(WebSocketConnectionContext);

  useEffect(() => {
    checkSubdomain(publicRoutes, websocketContext, history, publicRoutesNoRedirect);
  }, []);

  // Handle logout if certain conditions are met
  useEffect(() => {
    if (
      websocketContext?.assignedTask?.notification_type === 'firm_Admin_change' ||
      websocketContext?.assignedTask?.notification_type === 'firm_deactivated' ||
      websocketContext?.clientLogout
    ) {
      handleLogout();
    }
  }, [websocketContext]);

  const hostname = window.location.hostname;

  // Determine the redirect path based on the hostname
  let redirectPath = '/login';
  if (hostname === 'localhost') {
    redirectPath = '/login';
  } else if (!constants.APP_DOMAINS.includes(hostname)) {
    redirectPath = '/dashboard';
  }

  return (
    <Router>
      <Switch>
        <AppRoute exact path="/" component={() => <Redirect to={redirectPath} />} />
        {constants.APP_DOMAINS.includes(hostname) && mainRoutes.map((item, j) => <AppRoute exact {...item} key={j} />)}
        {subRoutes.map((item, k) => (
          <AppRoute {...item} key={k} />
        ))}
      </Switch>
    </Router>
  );
}

export default AppRouter;
