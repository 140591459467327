const constants = {
  cognito: {
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  },
  designations: [
    'Accountant',
    'Associate Attorney',
    'Attorney',
    'Billing Specialist',
    'Bookkeeper',
    'Business Manager',
    'Counsel',
    'Data Entry Clerk',
    'Deputy Director',
    'Finance Manager',
    'IT Manager',
    'Law Clerk',
    'Legal Assistant',
    'Legal Intake Specialist',
    'Legal Secretary',
    'Office Manager',
    'Paralegal',
    'Partner',
    'Receptionist',
    'Staff',
    'Other',
  ],
  user_types: [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Attorney',
      value: 'attorney',
    },

    {
      label: 'Staff',
      value: 'staff',
    },
  ],
  upload_doc_types: {
    pdf: 'pdf',
    docx: 'docx',
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    doc: 'doc',
    'application/msword': 'doc',
    'image/png': 'image',
    'image/jpeg': 'image',
    'image/jpg': 'image',
    'video/mp4': 'video',
  },
  CONTACT: 'CONTACT',
  CONTACT_LIST_STARTED: 'CONTACT_LIST_STARTED',
  CONTACT_LIST__SUCCESS: 'CONTACT_LIST__SUCCESS',
  CONTACT_LIST__FAILURE: 'CONTACT_LIST__FAILURE',
  CONTACT_DETAILS_STARTED: 'CONTACT_DETAILS_STARTED',
  CONTACT_DETAILS_SUCCESS: 'CONTACT_DETAILS_SUCCESS',
  CONTACT_DETAILS_FAILURE: 'CONTACT_DETAILS_FAILURE',
  USER_LIST_STARTED: 'USER_LIST_STARTED',
  USER_LIST__SUCCESS: 'USER__SUCCESS',
  USER_LIST__FAILURE: 'USER__FAILURE',
  USER_DETAILS_STARTED: 'CONTACT_DETAILS_STARTED',
  USER_DETAILS_SUCCESS: 'CONTACT_DETAILS_SUCCESS',
  USER_DETAILS_FAILURE: 'CONTACT_DETAILS_FAILURE',
  USER_CASE_LIST_STARTED: 'USER_CASE_LIST_STARTED',
  USER_CASE_LIST_FAILURE: 'USER_CASE_LIST_FAILURE',
  USER_CASE_LIST_SUCCESS: 'USER_CASE_LIST_SUCCESS',
  date_format: 'mm/dd/yy',
  date_format_upper: 'MM/DD/YY',
  date_month_format: 'DD/MM',
  date_time_format: 'MM/DD/yyyy [,] HH:mm:ss',
  date_time_format_without_second: 'MM/DD/yyyy [,] HH:mm',
  date_time_format_without_second_12: 'MM/DD/yyyy [,] hh:mm A',
  time_format_without_second_12: 'hh:mm A', //with AM and PM - 12 hr format
  time_format_without_second_24: 'HH:mm', // with no AM and PM and is 24 hours format
  last_updated_format: 'YYYY-MM-DD',
  full_month_DateYear_Fulltime_12hr: 'MMMM Do YYYY, h:mm:ss a',
  hour_minute_12_format: 'hh:mm A',
  hour_minute_24_format: 'HH:mm A',
  month_date_time_12_format: 'MM/DD[,] hh:mm A',
  month_date_time_24_format: 'MM/DD[,] HH:mm A',
  month_date_year_format: 'MM/DD/YYYY',
  month_date_year_time_format: 'MM/DD/YYYY[,] hh:mm',
  month_date_year_time_12_format_2: 'MM/DD/YY[,] hh:mmA',
  month_date_year_time_12_format: 'MM/DD/YYYY[,] hh:mm A',
  month_date_year_time_24_format: 'MM/DD/YYYY[,] HH:mm A',
  month_date_year_time_seconds_12_format: 'MM/DD/YYYY[,] hh:mm:ss A',
  month_date_year_time_seconds_24_format: 'MM/DD/YYYY[,] HH:mm:ss A',

  SAVE_CONTACT_IMAGE_STARTED: 'SAVE_CONTACT_IMAGE_STARTED',
  SAVE_CONTACT_IMAGE_SUCCESS: 'SAVE_CONTACT_IMAGE_SUCCESS',

  USER_DETAILS_IMAGE_UPDATE_STARTED: 'USER_DETAILS_IMAGE_UPDATE_STARTED',
  USER_DETAILS_IMAGE_UPDATE_SUCCESS: 'USER_DETAILS_IMAGE_UPDATE_SUCCESS',
  REMOVE_PROFILE_IMG_URL: 'REMOVE_PROFILE_IMG_URL',

  permissions: {
    WRITE: 'write',
    READ: 'read',
    HIDDEN: 'hidden',
    VISIBLE_PRACTICE_AREA: 'visible_practice_area',
    ADD_NEW_USERS: 'add_new_users',
    EDIT_PERMISSIONS: 'edit_permissions',
    DELETE_ITEMS: 'delete_items',
    EDIT_CUSTOM_FIELDS: 'edit_custom_fields',
    MANAGE_FIRM_SETTINGS: 'manage_firm_settings',
  },
  ADMIN_PERMISSION_POLICY: {
    automation: 'write',
    billing: 'write',
    calendar: 'write',
    cases: 'write',
    contacts: 'write',
    dashboard: 'write',
    files: 'write',
    leads: 'write',
    messages: 'write',
    reports: 'write',
    templates: 'write',
    tasks: 'write',
    settings: {
      add_new_users: true,
      edit_permissions: true,
      delete_items: true,
      permanently_delete_items: true,
      edit_export_import: true,
      manage_firm_settings: true,
      cases_restrict_to_linked_case: true,
      billing_restrict_time_entries: false,
      reports_restrict_personal_report: true,
      visible_practice_area: [],
    },
  },
  STRIPE_3D_MESSAGE: 'Your card was declined. This transaction requires authentication.',
  NON_FIRM_ADMIN_SUBSCRIPTION_CANCEL_MESSAGE: 'Please contact firm admin for the subscription',

  APP_DOMAINS: [
    'develop.lawft.com',
    'qa.lawft.com',
    'staging.lawft.com',
    'app.lawft.com',
    'login.lawft.com',
    'www.develop.lawft.com',
    'www.qa.lawft.com',
    'www.staging.lawft.com',
    'www.app.lawft.com',
    'www.login.lawft.com',
    'localhost',
  ],
};

export const flatFee = 'flat fee';
export const contingencyFee = 'contingency fee';
export const evergreenRetainer = 'evergreen retainer';
export const standardRetainer = 'standard retainer';
export const subscription = 'subscription';

export const PI_PROVIDERS_TYPE = ['medical_provider', 'vendor'];

export const notificationTypes = [
  'new_case_added',
  'new_templates',
  'new_lead',
  'updates_to_contacts',
  'added_to_case',
  'added_to_lead',
  'new_templates',
  'inbound_text_message',
  'secure_message',
  'invitation',
  'assignment',
  'new_note',
  'file_added',
  'document_created',
  'payment_recorded',
  'new_payment_request',
  'new_invoice',
  'file_sharing',
  'request_esignature',
  'reminder',
];

export const filterData_default = {
  priority: '',
  date: '3-months',
  practice_area: '',
  users: [],
};

export const defaultCaseFilter = {
  fileType: '',
  tag: '',
  case: '',
  selectedFolder: '',
  fileStatus: '',
};

export default constants;
