import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ModalHeader } from 'shared/ModalHeader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function OnlineLeadsModal({ onHide }) {
  const actionBody = (rowData) => {
    return (
      <div className="flex align-items-center justify-content-center">
        <i className="pi pi-eye" onClick={() => null} />
      </div>
    );
  };
  return (
    <Dialog
      visible
      modal
      header={<ModalHeader title="Online Leads" onHide={onHide} />}
      onHide={onHide}
      style={{ maxWidth: '70vw', display: 'flex', flexDirection: 'column' }}
      closable={false}
    >
      <div className="w-100">
        <div
          className="flex-grow-1 w-100"
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            transition: 'width 0.3s ease', // Smooth transition for resizing
          }}
        >
          <div className="col-12 p-0 datatable-responsive">
            <DataTable value={[]} className="p-datatable-responsive">
              <Column header="Name" field="nickname" />
              <Column header="Date Added" />
              <Column header="Submitted Form" />
              <Column header="Actions" body={actionBody} />
            </DataTable>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
