import React, { useRef, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { useUserDetailsContext } from 'context/userDetailsContext';
import 'react-quill/dist/quill.snow.css';

const TextSnippetWrapper = forwardRef(({ type = 'text', onChange, ...rest }, ref) => {
  const { userDetails } = useUserDetailsContext();
  const snippets = userDetails?.access_level !== 'client' ? userDetails?.text_snippets_map || {} : {};
  const quillRef = useRef(null); // Reference to ReactQuill component

  const handleSnippetReplacement = (input) => {
    // Use regex to find words that match snippets in the text
    const regex = new RegExp(`\\b(${Object.keys(snippets).join('|')})\\b`, 'g');
    return input.replace(regex, (matched) => snippets[matched] || '');
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Only process the input if the last character is a space or tab
    if (/\s/.test(inputValue.slice(-1))) {
      inputValue = handleSnippetReplacement(inputValue);
      e.target.value = inputValue; // Update the event's value directly
    }

    if (onChange) {
      onChange(e); // Pass the entire event object to the onChange prop
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault(); // Prevent the default tab behavior (focus shift)
      let inputValue = e.target.value;

      // Process the entire input string
      inputValue = handleSnippetReplacement(inputValue);
      e.target.value = inputValue; // Update the event's value directly

      if (onChange) {
        onChange(e); // Pass the entire event object to the onChange prop
      }
    }
  };

  const handleEditorChange = (content, delta, source) => {
    if (quillRef.current && source === 'user') {
      // Only process user-initiated changes
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection(); // Get the current cursor position

      if (selection) {
        const cursorPosition = selection.index;
        const textBeforeCursor = quill.getText(0, cursorPosition); // Extract plain text (no HTML tags)

        // Get the last word before the cursor
        const words = textBeforeCursor?.trim().split(/\s+/);
        const lastWord = words[words?.length - 1];

        // Check if the last word matches a snippet
        if (snippets[lastWord] && delta?.ops?.[1]?.insert === ' ') {
          // Replace the word using Quill's insertText (to preserve rich content formatting)
          const start = cursorPosition - lastWord.length; // Position to replace the last word
          const snippet = snippets[lastWord];

          // Delete the last word and insert the snippet in its place
          quill.deleteText(delta?.ops[1]?.insert === ' ' ? start - 1 : start, lastWord?.length);
          quill.insertText(delta?.ops[1]?.insert === ' ' ? start - 1 : start, snippet);

          // Move the cursor to the end of the inserted snippet
          setTimeout(() => {
            quill.setSelection(start + snippet.length, 0); // Place the cursor after the snippet
          }, 0);

          // Pass the updated HTML content (with snippets) to onChange
          if (onChange) {
            onChange(quill.root.innerHTML);
          }
        } else {
          // If no snippet match, just pass the content as is
          if (onChange) {
            onChange(content);
          }
        }
      } else {
        if (onChange) {
          onChange(content);
        }
      }
    }
  };

  return (
    <>
      {type === 'textarea' ? (
        <InputTextarea ref={ref} onChange={handleChange} {...rest} />
      ) : type === 'editor' ? (
        <ReactQuill {...rest} ref={quillRef} onChange={handleEditorChange} />
      ) : (
        <InputText ref={ref} onChange={handleChange} {...rest} />
      )}
    </>
  );
});

export default TextSnippetWrapper;
