import React from 'react';
import { Button } from 'primereact/button';

import Filter from './Filter';
import constants from 'constants/index';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useLeadContext } from 'modules/lead/Context/LeadContext';

export default function Header(props) {
  const { setAddToggle, filter, filterOnChange, refreshList, setOnlineLeadToggle } = props;
  const userDetails = useUserDetailsContext();

  const { practiceAreaList, userList } = useLeadContext();

  return (
    <React.Fragment>
      <GlobalLoader />
      <div className="d-flex justify-content-between align-items-center ">
        <h1 className="title mb-0">Leads</h1>
      </div>
      {/* <div className="font-italic  mb-3">Total Leads : {leadCount}</div> */}
      <div className="d-flex align-items-center mb-3 justify-content-between flex-wrap">
        <Filter {...{ practiceAreaList, userList, filter, filterOnChange, refreshList }} />
        <div className="mt-3 mt-lg-0">
          <Button label="Online Leads" className="p-button-primary button outline ms-2" onClick={setOnlineLeadToggle} />
          {userDetails.getPermission('leads') === constants.permissions.WRITE && (
            <Button
              label="Add New Lead"
              icon="pi pi-plus"
              className="button p-button p-component p-button p-button-primary ms-2"
              onClick={setAddToggle}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
